import React, { useEffect } from "react";
import { Form, Input, Button, Divider, Space, Select, Col, Row } from "antd";
import { MinusCircleOutlined, PlusOutlined } from "@ant-design/icons";
import "./wastelog.css";

const { Option } = Select;

const reasons = [
  { abbrv: "F", Explanation: "Floor" },
  { abbrv: "C", Explanation: "Contaminated" },
  { abbrv: "E", Explanation: "Expired" },
  { abbrv: "U", Explanation: "Unusable" },
  { abbrv: "D", Explanation: "Damaged" },
  { abbrv: "R", Explanation: "Recall" },
  { abbrv: "R&D", Explanation: "Research &  Development" },
  { abbrv: "RR", Explanation: "Retail Return" },
  { abbrv: "WR", Explanation: "Wholesale Return" },
  { abbrv: "SM", Explanation: "Spent Material" },
  { abbrv: "CC", Explanation: "Customer Care Replacement" },
  { abbrv: "NC", Explanation: "Non-conformance" },
  { abbrv: "TR", Explanation: "Testing Requirment" },
  { abbrv: "PL", Explanation: "Processing Loss" },
];

const methods = [
  { abbrv: "TC", Explanation: "Trash Compost" },
  { abbrv: "D", Explanation: "Drain(liquid only)" },
  { abbrv: "TR", Explanation: "Testing Requirment" },
];

const matrices = [
  { abbrv: "F", Explanation: "Flower" },
  { abbrv: "S", Explanation: "Shatter" },
  { abbrv: "K", Explanation: "Kitchen Concentrate" },
  { abbrv: "E", Explanation: "Edible" },
  { abbrv: "T", Explanation: "Trim" },
  { abbrv: "X", Explanation: "Cartridge" },
  { abbrv: "SY", Explanation: "Syringe" },
  { abbrv: "TO", Explanation: "Topical" },
];

export const NoteCreator = (props) => {
  return (
    <>
      <Divider>Disposal Notes:</Divider>
      <Form.List name="notes" initialValue={[1]}>
        {(fields, { add, remove }, { errors }) => (
          <>
            {fields.map(({ key, name, fieldKey, ...restField }, i) => (
              <>
                <Space size={0}>
                  <Form.Item
                    className="wastelog-note-item"
                    {...restField}
                    name={[name, "matrix"]}
                    fieldKey={[fieldKey, "matrix"]}
                    rules={[
                      {
                        required: true,
                        message: "Missing gross Matrix",
                      },
                    ]}
                  >
                    <Select placeholder="Matrix" style={{ width: "100px" }}>
                      {matrices.map((matrix) => (
                        <Option value={matrix.abbrv}>
                          {`${matrix.abbrv}(${matrix.Explanation})`}
                        </Option>
                      ))}
                    </Select>
                  </Form.Item>
                  <Form.Item
                    className="wastelog-note-item"
                    {...restField}
                    name={[name, "package_id"]}
                    fieldKey={[fieldKey, "package_id"]}
                    rules={[
                      {
                        required: true,
                        message: "Missing package ID",
                      },
                    ]}
                  >
                    <Input
                      placeholder="Package ID"
                      style={{ width: "100px" }}
                    />
                  </Form.Item>
                  <Form.Item
                    className="wastelog-note-item"
                    {...restField}
                    name={[name, "batch_id"]}
                    fieldKey={[fieldKey, "batch_id"]}
                    rules={[
                      {
                        required: true,
                        message: "Missing Batch ID",
                      },
                    ]}
                  >
                    <Input placeholder="Batch ID" style={{ width: "100px" }} />
                  </Form.Item>
                  <Form.Item
                    className="wastelog-note-item"
                    {...restField}
                    name={[name, "qty"]}
                    fieldKey={[fieldKey, "qty"]}
                    rules={[
                      {
                        required: true,
                        message: "Missing QTY",
                      },
                    ]}
                  >
                    <Input placeholder="QTY" style={{ width: "70px" }} />
                  </Form.Item>
                  <Form.Item
                    className="wastelog-note-item"
                    {...restField}
                    name={[name, "med_equivalent"]}
                    fieldKey={[fieldKey, "med_equivalent"]}
                    rules={[
                      {
                        required: true,
                        message: "Missing Med Equivalent",
                      },
                    ]}
                  >
                    <Input
                      placeholder="Med Equivalent"
                      style={{ width: "125px" }}
                    />
                  </Form.Item>
                  <Form.Item
                    className="wastelog-note-item"
                    {...restField}
                    name={[name, "reason"]}
                    fieldKey={[fieldKey, "reason"]}
                    rules={[
                      {
                        required: true,
                        message: "Missing reason",
                      },
                    ]}
                  >
                    <Select placeholder="Reason" style={{ width: "200px" }}>
                      {reasons.map((reason) => (
                        <Option value={reason.abbrv}>
                          {`${reason.abbrv}(${reason.Explanation})`}
                        </Option>
                      ))}
                    </Select>
                  </Form.Item>
                  <Form.Item
                    className="wastelog-note-item"
                    {...restField}
                    name={[name, "method"]}
                    fieldKey={[fieldKey, "method"]}
                    rules={[
                      {
                        required: true,
                        message: "Missing Method",
                      },
                    ]}
                  >
                    <Select placeholder="Method" style={{ width: "160px" }}>
                      {methods.map((method) => (
                        <Option value={method.abbrv}>
                          {`${method.abbrv}(${method.Explanation})`}
                        </Option>
                      ))}
                    </Select>
                  </Form.Item>
                  {fields.length > 1 ? (
                    <MinusCircleOutlined
                      className="dynamic-delete-button"
                      onClick={() => remove(name)}
                    />
                  ) : null}
                </Space>
                <Divider />
              </>
            ))}
            <Form.Item
              label=" "
              colon={false}
              labelCol={{
                span: 0,
              }}
              wrapperCol={{
                span: 24,
              }}
            >
              <Button
                type="dashed"
                onClick={() => add()}
                style={{ width: "80%" }}
                icon={<PlusOutlined />}
              >
                Add a note
              </Button>
            </Form.Item>
            <Divider />
          </>
        )}
      </Form.List>
    </>
  );
};
